<template>
  <div class="company-partners">
    <div class="company-partners__container">
      <div class="company-partners__breadcrumbs"></div>
      <div class="company-partners__top _container">
        <h1 class="company-partners__title main-title">{{ $t("certific") }}</h1>
        <h2 class="company-partners__small-title">
          {{ $t("sendRegion") }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      partners: [
        require("../assets/images/partners/partner-2.png"),
        require("../assets/images/partners/partner-1.png"),
        require("../assets/images/partners/partner-2.png"),
        require("../assets/images/partners/partner-1.png"),
        require("../assets/images/partners/partner-2.png"),
        require("../assets/images/partners/partner-1.png"),
        require("../assets/images/partners/partner-2.png"),
        require("../assets/images/partners/partner-1.png"),
        require("../assets/images/partners/partner-2.png"),
        require("../assets/images/partners/partner-1.png"),
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
